<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 18:05:16
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-07 08:42:37
-->
<template>
  <body-layer>
    <div class="base">
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({name: 'liveDetail', query: {id: item.id}})">
          <div class="image" :style="`background-image:url(${item.thumbnail})`">
          </div>
          <div class="context">
            <div class="title">
              <span>{{item.post_title}}</span>
            </div>
            <div class="dest">
              <span>{{item.post_excerpt}}</span>
            </div>
            <div class="tag" v-if="item.post_keywords">
              <i v-for="(d, idx) in item.post_keywords.split(',')" :key="idx">{{d}}</i>
            </div>
            <div class="price">{{item.price}}<span>元/科起</span></div>
            <div class="some">
              <div class="myBtn detail" @click="$router.push({name: 'liveDetail', query: {id: item.id}})">课程详情</div>
              <div class="myBtn call" @click="$router.push({name: 'advisory'})">立即咨询</div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; padding-top: 20px;">
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="page" :page-size="20"
          layout="prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
    </div>
  </body-layer>
</template>

<script>
import bodyLayer from '@/components/bodyLayer.vue';
import http from '@/util/http'
import { mapState } from 'vuex';
export default {
  components: { bodyLayer },
  data(){
    return{
      cid: '',
      list: [],
      page: 1,
      total: 0
    }
  },
  computed: {
    ...mapState(['pcategory'])
  },
  mounted() {
    if(this.$route.query.cid){
      this.cid = this.$route.query.cid;
    }
    this.getList();
  },
  methods: {
    async getList(){
      var params = {
        page: this.page,
        uniqid: this.$store.getters.uniqid,
      }
      console.log(this.pcategory);
      if(this.cid) params.cate_id = this.pcategory[this.cid].id;
      var { data } = await http.post('/api/course/getList', params);
      this.total = data.total;
      this.list = data.data;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    }
  },
}
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  position: relative;

  .list {
    width: 100%;

    .item {
      width: 100%;
      padding: 30px 0;
      display: flex;
      border-bottom: 1px solid rgba(232, 232, 232, 1);
      cursor: pointer;
      &:first-child{
        padding-top: 0;
      }
      .context {
        padding-left: 20px;
        width: 100%;
        height: 180px;
        position: relative;
        .some{
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          .myBtn{
            width: 140px;
            height: 40px;
            line-height: 40px;
            border-radius: 40px;
            font-size: 18px;
            text-align: center;
            border: 1px solid rgba(153, 153, 153, 100);
            box-sizing: content-box;
            cursor: pointer;
            &.detail{
              margin-right: 15px;
              color: rgba(102, 102, 102, 1);
              background-color: #FFF;
            }
            &.call{
              color: #FFF;
              background-color: rgba(255, 106, 41, 1);
              border: 1px solid rgba(255, 106, 41, 1);
            }
          }
        }
        .price {
          font-size: 30px;
          color: rgba(255, 106, 41, 1);
          position: absolute;
          left: 15px;
          bottom: 0;
          font-weight: bold;
          span{
            font-size: 20px;
            font-weight: 400;
          }
        }

        .tag {
          display: flex;

          i {
            height: 20px;
            line-height: 20px;
            padding: 0 8px;
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            background: rgba(242, 242, 242, 1);
            display: inline-block;
            margin-right: 5px;
            font-style: normal;
          }
        }

        .dest {
          width: 900px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 15px 0;
          line-height: 1;
          span {
            color: rgba(102, 102, 102, 1);
            font-size: 14px;
          }
        }

        .title {
          width: 900px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1;
          span {
            color: rgba(0, 0, 0, 1);
            font-size: 16px;
          }
        }
      }

      .image {
        width: 270px;
        height: 180px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>